.App {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: #333;
}

.speech-recognition {
  margin-bottom: 20px;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

button.listening {
  background-color: #f44336;
}

.error {
  color: red;
  font-weight: bold;
}

.translation-display {
  margin-top: 20px;
}

.recognition-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.recognition-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
}

.recognition-item .original {
  font-weight: bold;
  margin-bottom: 5px;
}

.recognition-item .translated {
  color: #555;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }
}
